@import '../../../../styles/mixins';
@import '../../../../styles/colors';

.container {
  max-width: 100%;
}

.link {
  text-decoration-line: underline;
  color: $color-bright-blue;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.dialog {
  &-link {
    font-size: 18px;
  }
}

.switch {
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  font-size: 14px;

  label::before {
    margin-top: 12px;
  }

  label::after {
    margin-top: 12px;
  }
}

.cardcontrol {
  @include break-point(mobile, mobile-xs, ipad-portrait) {
    margin-left: 6px;
    margin-right: 6px;
    margin-top: 6px;
  }

  &-disclaimer {
    text-align: center;
  }

  &-card {
    padding: 38.5px 33px 41px 18.5px;
    margin-bottom: 16px;
    border-radius: 8px;
    box-shadow: none;
    border: 1px solid $color-light-gray;

    @include break-point(mobile) {
      box-sizing: border-box;
      padding: 25.5px 91px 41px 0px;
    }
  }

  &-card-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 30px;

    @include break-point(ipad-portrait) {
      padding-left: 30px;
    }

    @include break-point(mobile) {
      margin-left: 20px;
    }
  }

  &-option-label {
    font-weight: 600;
    width: fit-content;
    height: 28px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    flex: none;
    order: 2;
    flex-grow: 0;
  }

  &-option-sublabel {
    width: fit-content;
    height: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    flex: none;
    order: 2;
    flex-grow: 0;
  }

  &-label-link {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.disclaimer-wrap {
  text-align: center;
  padding: 16px 86px !important;
  margin-bottom: 16px;

  box-shadow: none;
  border: 1px solid $color-light-gray;

  @include break-point(mobile, mobile-xs) {
    padding: 16px 14px !important;
  }

  @include break-point(ipad-portrait) {
    padding: 16px 34px !important;
  }
}
