@import '../../../styles/mixins';
@import '../../../styles/colors';

.container {
  max-width: 100%;

  .row {
    flex-wrap: nowrap;
    padding-left: 8px;
    align-items: center;

    span:nth-child(1) {
      height: 24px;
      width: 24px;
    }

    span:nth-child(2) {
      padding-left: 36px;
      flex-grow: 1;
      text-align: left;
    }
  }
}

.open-style {
  margin: 0.6rem 0;
  width: 100%;
  position: initial;
  border-bottom: none;
  border-radius: 12px;
}
.common-style {
  height: 14.73px;
  width: 14.73px;
}

.success {
  @extend .common-style;
  color: $color-stage-success;
}

.error {
  @extend .common-style;
  color: $color-stage-error;
}

.info {
  @extend .common-style;
  color: $color-visa-light-blue;
}

.warning {
  @extend .common-style;
  color: $color-visa-gold;
}

.align-left {
  display: flex;
  align-items: flex-start;
}
.close-button-icon {
  margin: 0 !important;
}
.close-icon-col {
  max-width: fit-content !important;
}
