@import '../../../styles/mixins';

.container > :first-child {
  max-width: 534px;
  border-radius: 32px;
  padding: 5rem 5.25rem 3rem 5.25rem;
  border: none;

  @include break-point(mobile) {
    max-width: 396px;
    width: auto;
    margin: 80px 16px !important;
  }

  @include break-point(mobile-xs) {
    width: auto;
    padding: 2rem;
    margin: 80px 10px !important;
  }
}

.close-icon-wrap {
  padding: 0px;
  position: relative;
  left: 41px;
  bottom: 25px;

  @include break-point(mobile-xs) {
    position: relative;
    left: unset;
    bottom: unset;
  }
}

.header {
  text-align: center;
  margin-bottom: 0.8125rem;

  .title {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
}

.body {
  text-align: center;
  padding-bottom: 0px;
  --theme-dialog-padding: 0;

  .body-text {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

.footer {
  padding-top: 0px;
  padding-bottom: 0px;
}
