@import '../../../../../src/styles/mixins.scss';
@import '../../../../../src/styles/colors';

//  list container with border
.list-container {
  border: 1px solid $color-light-gray;
  border-radius: 12px;
}
//  reusable style
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

//  each notification container: with 2 column childs.
.notification-container {
  @extend .flex;
  padding: 30px 40px;
  justify-content: space-between;
  border-bottom: 1px solid $color-light-gray;
  align-items: flex-start;
}
.notification-container:last-child {
  border-bottom: none;
}

//  first col with transaction label, marchant name and city
.inner-column {
  flex-direction: column;
}
//  sec. column with transactiondate and amount
.inner-column-flex-end {
  @extend .inner-column;
  display: flex;
  align-items: flex-end;
}

.icon-main-text {
  display: flex;
}

.right-space {
  margin-right: 1rem;
}

.main-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  word-break: break-word;
  margin-bottom: 0px;

  @include break-point(mobile-xs) {
    font-size: 14px;
  }
}

.sub-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0px;

  @include break-point(mobile-xs) {
    font-size: 16px;
  }
}

.none-container {
  display: flex;
  padding: 52px 0;
  border-radius: 1em;
  justify-content: center;
  border: 1px solid $color-light-gray;
}

.none-title {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.history-container {
    display: flex;
    flex-direction: column;
    padding: 42px 40px 34px 40px;
    border-bottom: 1px solid $color-light-gray;
    row-gap: 12px;

    @include break-point(mobile) {
      padding: 26px 32px;
    }
    @include break-point(mobile-xs) {
      padding: 26px 20px;
    }
}

.service-offering-details {
  display: flex;
  column-gap: 16px;
  align-items: center;

  @include break-point(mobile-xs, mobile) {
    column-gap: 12px;
  }

  :last-child {
    text-align: right;
    flex-grow: 1
  }
}

.merchant-details {
  display: flex;
    :last-child {
      text-align: right;
      flex-grow: 1;
    }
}
