@import '../../../styles/colors';

.spinner-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
}
.spinner {
  width: 50px;
  height: 50px;
  z-index: 1001;
  border: solid 4px $color-primary-blue;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  transition: translate 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
