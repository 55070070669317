@import '../../../styles/mixins';
@import '../../../styles/colors';

.input-field {
  min-width: 100%;

  label:first-child {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: $color-black;
  }
}

.link {
  color: $color-primary-blue;
  font-weight: 400;
  font-size: 18px;
  word-break: break-word;
  text-decoration: none !important;
}

.currentPhone-label {
  margin-top: 20px;
  margin-bottom: 4px;
}

.note {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.text {
  margin-bottom: 1.6rem;
}
