@import '../../../../styles/mixins';
@import '../../../../styles/colors';

.container {
  max-width: 100%;
  box-sizing: border-box;

  .currency-symbol {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    margin-left: 7px;
  }

  .card {
    margin-bottom: 12px;
    box-shadow: none;
    border: 1px solid $color-light-gray;
    border-radius: 12px;
  }

  .hide-sms {
    display: none;
  }

  .alert-icon {
    width: 24px;
    height: 24px;
    margin-top: 8px !important;
    padding-top: 16px;
  }

  .alerts {
    padding: 24px 0px 24px 24px;
    display: flex;
    column-gap: 22px;

    @include break-point(desktop, desktop-xl, desktop-xxl) {
      padding: 18px 0px 18px 32px;
      column-gap: 0px;
    }

    @include break-point(ipad-portrait) {
      padding: 24px 0px 24px 30px;
      column-gap: 0px !important;
    }

    @include break-point(mobile-xs, mobile) {
      padding: 12px 0px 12px 32px;
    }

    .service-offering {
      display: flex;

      @include break-point(mobile-xs, mobile) {
        flex-direction: column;
      }

      .service-offering-title {
        flex-grow: 1;
        padding: 14px 0px 0px;

        @include break-point(desktop, desktop-xl, desktop-xxl) {
          padding: 14px 0px 0px 30px;
        }

        .label {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 4px;
        }

        .label-link {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          &:hover {
            text-decoration: underline;
          }
        }

        .description {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 4px;
        }
      }

      .service-offering-channels {
        display: flex;
        margin-left: 6px;
        column-gap: 22px;
        justify-content: flex-end;
        padding-top: 8px;

        @include break-point(mobile-xs, mobile) {
          justify-content: flex-start;
        }

        .sms {
          display: flex;
          align-items: center;
          flex-direction: column;

          @include break-point(mobile-xs, mobile) {
            flex-direction: row;
          }

          .label {
            @extend .checkbox-label;
          }
        }

        .email {
          display: flex;
          align-items: center;
          flex-direction: column;

          @include break-point(mobile-xs, mobile) {
            flex-direction: row;
          }

          .label {
            @extend .checkbox-label;
          }
        }
      }
    }

    .threshold-amount {
      width: 214px;
      padding-bottom: 30px;

      @include break-point(mobile-xs) {
        width: 168px;
      }

      @include break-point(desktop, desktop-xl, desktop-xxl) {
        width: 168px;
        padding-left: 32px;
      }
    }
  }
}

.checkbox-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0px;
}

.disabled-label {
  color: $color-pale-white;
}

.link {
  text-decoration-line: underline !important;
  color: $color-bright-blue !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-right: 5px;
}

.enable-notifications {
  text-align: center;
  padding: 16px 16px !important;
  margin-bottom: 16px;
  width: 100%;
  box-shadow: none;
  border: 1px solid $color-light-gray;
  border-radius: 12px;
}

.alerts-offering {
  @include break-point(ipad-portrait) {
    margin-left: 32px;
  }
}
