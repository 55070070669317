// @mixin break-point($point) {
//   @if $point == tablet {
//     @media (min-width: 768px) and (max-width: 1023px) {
//       @content;
//     }
//   } @else if $point == desktop {
//     @media (min-width: 1024px) and (max-width: 1199px) {
//       @content;
//     }
//   } @else if $point == desktop-xl {
//     @media (min-width: 1200px) {
//       @content;
//     }
//   }
// }

@mixin break-point($points...) {
  @each $point in $points {
    @if $point ==mobile-xs {
      @media (min-width: 320px) and (max-width: 479px) {
        @content;
      }
    } @else if $point ==mobile {
      @media (min-width: 480px) and (max-width: 767px) {
        @content;
      }
    } @else if $point ==ipad-portrait {
      @media (min-width: 768px) and (max-width: 1023px) {
        @content;
      }
    } @else if $point ==desktop {
      @media (min-width: 1024px) and (max-width: 1439px) {
        @content;
      }
    } @else if $point ==desktop-xl {
      @media (min-width: 1440px) and (max-width: 1599px) {
        @content;
      }
    } @else if $point ==desktop-xxl {
      @media (min-width: 1600px) {
        @content;
      }
    }
  }
}

@mixin background-opacity($color, $opacity) {
  background: $color;
  background: rgba($color, $opacity);
}

@mixin two-col($gutter) {
  @include break-point(desktop) {
    flex: 0 0 calc(50% - $gutter/2);
    max-width: calc(50% - $gutter/2);
  }
}
