@import '../../../../src/styles/mixins.scss';
@import '../../../../src/styles/colors';

.wrapper {
  width: 100%;
  height: 127px;
  border-radius: 10px;
  position: relative;
  max-width: 205px;
  margin: auto;

  .logo {
    position: absolute;
    bottom: 14.63px;
    left: 13.06px;
    margin: auto;
    height: 22.52px;
    width: 46.41px;
  }

  .plus-icon {
    position: absolute;
    bottom: 11px;
    right: 11px;
    margin: auto;
    height: 14.25px !important;
    width: 14.25px !important;

    &:hover,
    &:focus {
      height: 14.25px !important;
      width: 14.25px !important;
    }
  }

  .add-icon-background {
    position: absolute;
    height: 33.25px;
    width: 33.25px;
    background: $color-primary-blue;
    border-radius: 50%;
    bottom: 2px;
    right: 2px;
  }

  .add-card-button {
    position: absolute;
    bottom: -14px;
    right: -14px;
    height: auto;
    width: auto;
  }
}

.wrapper-blue {
  background: $color-primary-blue;
}

.wrapper-grey {
  background: linear-gradient(135deg, rgba(153, 153, 153, 0.15) 0%, rgba(50, 50, 50, 0.15) 100%);
}
