@import '../../../styles/mixins';
@import '../../../styles/colors';

.btn-span {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 21px !important;
}

.primary {
  @include break-point(desktop) {
    // width: 340px;
  }

  // @include break-point(desktop-xl) {
  //   width: 290px;
  // }

  span {
    letter-spacing: 0px;
  }
  height: 3.5em;
  text-transform: initial;
  margin: 0px;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  margin: 1.5rem 0 1rem 0;
  border-radius: 2px;
}

.secondary {
  span {
    @extend .btn-span;
    letter-spacing: 0px;
  }

  height: 52px;
  text-transform: initial;
  margin: 0px;
  margin: 0 0 1rem 0;
  border-radius: 2px;
  border: 2px solid $color-primary-blue;
}

.tertiary {
  span {
    @extend .btn-span;
    letter-spacing: 0px;
  }

  height: 3.5em !important;

  border: 0;
  //TODO: Remove all the !important so that they can be overridden from calling methods.
  color: $color-dark-gray !important;
  margin: 1rem 0 0 0;
  width: 100%;
  text-transform: initial;
  height: auto;
  background-color: white !important;

  &:hover {
    background: none !important;
    background-color: white !important;
    color: $color-dark-gray !important;
  }
}

.delete {
  span {
    @extend .btn-span;
    letter-spacing: 0px;
  }

  height: 3.5em !important;

  border: 0;
  //TODO: Remove all the !important so that they can be overridden from calling methods.
  color: $color-meroon !important;
  // margin: 0px;
  margin-top: 0px;
  width: 100%;
  text-transform: initial;
  height: auto;
  background-color: white !important;

  &:hover {
    background: none !important;
    background-color: white !important;
    color: $color-meroon !important;
  }
}

.add {
  span {
    @extend .btn-span;
    line-height: 24px !important;
    letter-spacing: 0px;
  }

  //TODO: Remove all the !important so that they can be overridden from calling methods.

  border: 2px dashed $color-light-gray;
  background-color: white !important;
  border-radius: 12px;
  color: $color-primary-blue !important;
  margin-left: 0px;
  width: 100%;
  text-transform: initial;
  height: 94px !important;
  margin-bottom: 10px;
  margin-top: 0px;

  &:focus {
    border: 0px !important;
    border-radius: 0px !important;
  }

  &:hover {
    border: 2px dashed $color-light-gray;
    border-radius: 12px;
    color: $color-primary-blue !important;
  }
}
