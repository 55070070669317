@import '../../../../styles/mixins';
@import '../../../../styles/colors';

.input-row {
  justify-content: space-between;
}

.input-field {
  span:last-child {
    align-items: flex-start !important;
  }
  --theme-input-placeholder: $color-black;

  //  MOBENB-20432
  &.expiration {
    label {
      @include break-point(desktop-xl, desktop-xxl) {
        font-size: 13px !important;
      }
    }
  }
}

.edit-card-label {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.edit-card-number {
  font-size: 1rem;
  line-height: 1.31rem;
}
