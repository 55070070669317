/// ANIMATIONS:

/// BUTTONS:

/// CONTAINERS:
body {
  margin: 0;
  font-family: 'Visa Dialect UI', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

form {
  width: 100%;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#app-main {
  flex-grow: 1;
}
/// CUSTOM COMPONENTS:

/// FORMS:

/// IMAGES:

/// INPUTS:

/// LINKS:

/// LISTS:

/// NAV:

/// TEXT:

/// MEDIA QUERIES:
