@import '../../../styles/mixins';
.body {
  line-height: 28px;
  margin-bottom: 0;
}

.container {
  text-align: center;
  max-width: 856px;
  box-sizing: border-box;
  margin: 0 auto;

  @include break-point(mobile-xs, mobile, ipad-portrait) {
    max-width: 95%;
  }

  @include break-point(mobile-xs, mobile) {
    [data-id='generic-main'] {
      padding: 32px 40px 48px 40px !important;
    }
  }
}
