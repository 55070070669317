@import '../../../../../src/styles/mixins.scss';
@import '../../../../../src/styles/colors';

.cardart-row {
  position: relative;
  height: 137px;
  width: 215px;
  margin: auto;
}

.button-row {
  margin: auto;
  max-width: 342px;
  height: 56px;
  margin-bottom: 40px;
  margin-top: 20px;
}

.page-subtitle {
  margin: 16px;
}

.panel-card {
  max-width: 900px;
}
