@import '../../../../src/styles/mixins.scss';
@import '../../../../src/styles/colors';

.delete-profile-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.5px;
  text-align: center;
  @include break-point(mobile) {
    font-size: 24px;
    line-height: 30px;
  }
}
