@import '../../../styles/mixins';
@import '../../../styles/colors';

.header-container {
  background: transparent !important;
  width: -webkit-fill-available;
  padding: 0 1.5rem !important;
  @include break-point(mobile, mobile-xs, ipad-portrait, tablet) {
    padding: 0 1rem !important;
  }
  @include break-point(mobile, mobile-xs, tablet, ipad-portrait) {
    // width: 100%;
    display: flex;
  }

  .navbrand {
    padding: 0;
  }
}

.button-text {
  text-transform: initial;
  span {
    letter-spacing: 0px;
  }
}
.nav-logo {
  text-decoration: none !important;

  @include break-point(mobile-xs) {
    height: 17.94px;
  }
}
.nav-cobrand {
  display: inline;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: $color-primary-blue;
  letter-spacing: -0.5px;
  margin-left: 12px;

  @include break-point(mobile-xs) {
    font-size: 18px;
    margin-left: 7.26px;
  }
}

.profile-nav-dropdown {
  div:nth-child(2) {
    padding: 0;
    background: $color-white !important;
    border-radius: 12px;
  }

  .profile-nav-list {
    li {
      background: $color-white !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      text-decoration: none !important;
      @include break-point(tablet, mobile, mobile-xs) {
        width: auto;
      }

      a {
        color: $color-black !important;
        font-weight: 600;
        width: 100%;
        padding: 1.5rem 0 !important;
        text-align: center;
        text-decoration: none !important;
      }
      a:hover {
        text-decoration: none !important;
      }
    }

    li:nth-child(2) {
      box-shadow: inset 0px 1px 0px #e1e1e1;

      a {
        color: $color-light-maroon !important;
      }
    }
  }

  .profile-nav-button {
    padding: 0 !important;
    background: $color-white !important;
    height: auto !important;

    .vds-avatar {
      padding: 0 !important;

      div {
        margin-right: 0px !important;
      }
    }
  }
}
