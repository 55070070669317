@import '../../../styles/colors';
@import '../../../styles/mixins';

.label {
  label:first-child {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: $color-black;
    margin-bottom: 9px;
  }
}

.input-row {
  justify-content: space-between;
}
.input-field {
  span:last-child {
    align-items: flex-start !important;
  }
}

.page-subtitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  margin: 16px;
}

.link {
  color: $color-primary-blue;
  font-weight: 600;
}

.checkbox {
  margin-left: -10px;
  font-size: 14px;
  font-weight: 600;

  .check-label {
    margin-bottom: 0px;

    @include break-point(mobile, mobile-xs) {
      width: auto;
    }
  }
}
