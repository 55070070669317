@import '../../node_modules/@visa/vds-web/dist/_sass/vds-colors';

$color-light-fog: rgba(236, 236, 232, 0.2);
$color-dark-fog: rgba(0, 0, 0, 0.16);
$color-pale-white: #cacaca;
$color-light-gray: #e1e1e1;
$color-dark-gray: #4a4a4a;
$color-dark-blue: #132482;
$color-sky-blue: #dfebff;
$color-medium-blue: #0042b3;
$color-bright-blue: #0052e1;
$color-primary-blue: #1434cb;
$color-snow-white: #d7d7de;
$color-stage-error: #d50000;
$color-stage-processing: #d7d7de;
$color-stage-success: #077844;
$color-stage-border: #dddde1;
$color-stage-background: #dfebff;
// $color-mercury: #e1e1e1;
$color-dashboard-positive-green: #1b4423;
$color-dashboard-negative-red: #c30000;
//TODO: remove color black and white
$color-black: #000;
$color-white: #fff;
$color-breadcrumb-title: #2a2e69;
$color-visa-gold: #f7b600;
$color-visa-blue: #1a1f71;
$color-visa-light-blue: #1869d6;
$color-lighter-gray: #797979;
$color-card-icon-blue: #003ea9;
$color-accordion-title-gray: #f5f5f5;
$color-mercury: #e5e5e5;
$color-meroon: #b3202a;
$color-gray: #8a847f;
$color-light-maroon: #d65151;
$color-link: #1434cb;
$color-anti-flash-white: #f0f0f0;
$color-white-smoke: #e6f2ff;
