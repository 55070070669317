@import '../../../styles/colors';
@import '../../../styles/mixins';

.listitem[aria-selected='true'] {
  a {
    .cardtile-custom-row {
      border: 1px solid $color-dark-blue;
      outline: none !important;
      background-color: $color-anti-flash-white;
    }
  }
}

.listitem {
  background-color: none;
  padding: 0;
  a {
    display: block;
    color: $color-black;
    text-align: start;
    &:focus {
      border: 1px solid $color-dark-blue;
      outline: none !important;
      .cardtile-custom-row {
        background-color: $color-anti-flash-white;
      }
    }
    .cardtile-custom-row {
      border-radius: 0;
      box-shadow: none;
      padding: 16px 16px 16px 24px !important;
      &:hover {
        background-color: #f0f0f0;
      }

      button {
        padding-right: 0;
      }
      svg {
        fill: $color-black;
        margin-right: 18px;
      }
    }
  }
}
