@import '../../../styles/mixins';
@import '../../../styles/colors';

.container {
  padding: 0px;
  margin: 0 auto;
  max-width: 534px;

  @include break-point(mobile-xs) {
    max-width: 479px;
  }

  @include break-point(mobile, ipad-portrait) {
    max-width: 534px;
  }

  .card {
    padding: 64px 95px;
    box-shadow: none;
    border: 1px solid $color-light-gray;
    border-radius: 32px;

    @include break-point(mobile-xs) {
      padding: 32px 26px;
      margin: 0px 16px 10px 16px;
    }

    @include break-point(mobile) {
      max-width: auto;
      margin: 0px 16px 10px 16px;
    }

    .card-content {
      padding: 0 !important;

      @include break-point(mobile) {
        padding: 0 !important;
      }
    }
  }

  .first-row {
    margin-bottom: 32px;

    @include break-point(mobile-xs, mobile) {
      margin-bottom: 16px;
    }
  }

  .alert-row {
    margin: 10px;
  }

  .panel-main-text {
    font-weight: 600;
    margin-bottom: 16px;
  }

  .page-title-flex {
    width: 100%;
  }

  .icon-space {
    margin-right: 1rem;
  }

  .panel-sub-text {
    margin-bottom: 24px;
  }
}

.panel-card-sub-text {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}

.back-icon {
  padding-left: 4px !important;
}
