@import '../../../../styles/mixins.scss';
@import '../../../../styles/colors';

.cardtile-row {
  width: auto !important;
  max-width: 534px;
  height: auto !important;
  padding: 24px 32px;
}

.add-icon {
  max-width: 1rem;
  margin-right: 1rem !important;
  fill: $color-primary-blue !important;
}

.custom-container {
  [data-id='generic-main'] {
    padding: 6px;
    border: none;
    margin-bottom: 2.5rem;
  }
}

.body {
  a {
    width: 100%;
    color: $color-black;
    margin-bottom: 24px;
    text-align: start;
  }
}
