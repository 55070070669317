@import '../../../styles/colors';

.child-container {
  color: $color-link;
}

.link-label {
  margin: 0;
  margin-right: 0.4em;
  text-decoration: none;
}

a:link,
a:visited {
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.icon-position {
  vertical-align: text-bottom;
}
