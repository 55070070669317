@import '../../../styles/mixins';
@import '../../../styles/colors';

.container {
  font-style: normal;

  .remember-me {
    margin-left: -8px;
    label {
      font-weight: 600;
    }
  }
  .forgot-links-row {
    text-align: center;
    .link {
      color: $color-primary-blue;
      font-weight: 600;
    }
  }
  .submit {
    font-size: 16px;
  }
}
