@import '../../../styles/colors';
@import '../../../styles/mixins';

.manage-cards[aria-selected='true'] {
  a {
    .inner-row {
      outline: none;
      background: $color-anti-flash-white;
    }
  }
}

.cards-content {
  max-width: inherit;
  display: block;
  position: relative;
  margin-bottom: 24px;
  z-index: 1;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
  border-radius: 12px;

  &:hover {
    box-shadow: 0px 8px 44px rgba(0, 0, 0, 0.24);
  }

  .content-category {
    background-color: $color-white;

    .content {
      padding: 0;

      .button {
        width: 100% !important;
        height: 88px;
        padding: 20px 16px 20px 24px !important;
        border: none;
        border-radius: 12px;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        &:focus-visible {
          border: 1px solid $color-dark-blue;
        }

        .icon {
          fill: #000;
          width: 14px;
          height: 14px;
        }

        .card-text {
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 0;
        }

        .card-sub-text {
          font-size: 14px;
          color: $color-dark-gray;
          font-weight: 400;
        }

        .cardtile-row {
          border-radius: 0;
          box-shadow: none;
          padding: 0;
          text-align: start;
          background: none;

          button {
            padding-right: 0;
          }
        }
      }
    }
  }

  .content-main {
    padding: 0;

    .menu {
      max-height: fit-content;
      box-shadow: none;
      border: none;
      padding: 0;
      margin: 0;

      .listbox {
        background-color: unset !important;
        max-height: fit-content;
        min-width: auto;
        padding: 0;
        margin: 0;

        .manage-cards {
          background: $color-white;
          box-shadow: inset 0px 1px 0px $color-light-gray;
          padding: 0;

          .card-text {
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 0;
          }

          a {
            display: block;
            height: 100%;
            color: $color-black;
            text-decoration: none;
            text-align: center;
            margin-top: 16px;
            border-radius: 0px 0px 12px 12px;
            height: 72px;

            &:focus-visible {
              border: 1px solid $color-dark-blue;
              outline: none;
              background: $color-anti-flash-white;
            }

            &:hover {
              background-color: $color-anti-flash-white;
            }

            .inner-row {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;

              .icon {
                fill: $color-black;
                margin-right: 12px;
              }
            }
          }
        }
      }

      .profile-nav-button {
        padding: 0 !important;
        background: $color-white !important;
        height: auto !important;

        .vds-avatar {
          padding: 0 !important;
        }
      }

      .button {
        width: 100% !important;
        height: 88px;
        padding: 20px 16px 20px 24px !important;
        border: none;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .icon {
          fill: #000;
        }

        .card-text {
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 0;
        }

        .card-sub-text {
          font-size: 14px;
          color: $color-dark-gray;
          font-weight: 400;
        }

        .cardtile-row {
          border-radius: 0;
          box-shadow: none;
          padding: 0;
          text-align: start;
          background: none;

          button {
            padding-right: 0;
          }
        }
      }
    }
  }
}

.content-show .content-category .content .button {
  border-radius: 12px 12px 0px 0px;
  &:hover {
    background-color: $color-anti-flash-white;
  }
  &:focus-visible {
    background-color: $color-anti-flash-white;
  }
}
.content-hide {
  .content-main {
    display: none;
  }
}
