.skip-btn {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}
.skip-btn:focus,
.skip-btn:active {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;
  width: auto;
  height: auto;
  overflow: auto;
  padding: 10px;
  border-radius: 1px;
  text-align: center;
  font-size: 1.2em;
  z-index: 999;
}
