@import '../../../../styles/mixins';
@import '../../../../styles/colors';

.container {
  display: flex;
  // margin-top: 5rem;
  align-items: center;
  justify-content: center;
  position: relative;
}

.alert-row {
  margin: 10px;
}

.page-subtitle {
  @include break-point(mobile, mobile-xs, ipad-portrait) {
    margin: 10px 0 24px 0;
  }

  margin: 1.5rem 0 2rem 0;
  letter-spacing: -0.5px;
}

.flex-container {
  // margin: 4.8rem 0 0 0;
  max-width: 100%;
  display: flex;

  @include break-point(mobile, mobile-xs, ipad-portrait, tablet) {
    flex-direction: column;
    min-width: min-content;
  }
}

.left-container {
  width: 25%;
  padding: 0 1.5rem;

  @include break-point(mobile, mobile-xs, ipad-portrait, tablet) {
    display: block;
    width: auto;
    padding: 0 1rem;
  }
  @include break-point(desktop) {
    max-width: 304px;
    width: 100%;
  }

  @include break-point(desktop-xl, desktop-xxl) {
    max-width: 318px;
    width: 100%;
  }
}

.right-container {
  width: 75%;
  margin-bottom: 6rem;
  padding: 0 6rem 0 4rem;

  @include break-point(mobile, mobile-xs, ipad-portrait, tablet) {
    display: block;
    width: auto;
    padding: 1rem;
    background: $color-white;
    box-shadow: inset 0px 1px 1px $color-light-gray;
  }

  @include break-point(mobile, mobile-xs, ipad-portrait) {
    padding: 24px 16px 16px 16px;
  }

  @include break-point(desktop) {
    width: 70%;
  }
}

.right-container-no-action-btns {
  margin-bottom: 1rem;
}

.alert {
  margin: 10px 0 32px 0;
  width: 100%;
}

.title {
  display: block;
}

.main {
  @include break-point(mobile, mobile-xs, ipad-portrait, tablet) {
    // overflow: scroll;
  }
}

.card-list {
  margin-top: 10px;
  position: relative;
  height: 88px;
  margin-bottom: 24px;
  min-width: auto;

  @include break-point(mobile, mobile-xs, ipad-portrait) {
    margin-bottom: 16px;
  }
}

.nav-item {
  padding: 0 !important;

  @include break-point(mobile, mobile-xs, ipad-portrait, tablet) {
    margin-bottom: 1rem;
  }
}

.tab {
  height: 2rem;
  border: none;
  margin-top: 5px;
  border-bottom-width: 0 !important;
  background-color: transparent !important;
  padding: 0.3rem 1rem 0rem 1rem !important;
  &:focus {
    outline: none !important;
  }

  @include break-point(mobile, mobile-xs, ipad-portrait) {
    height: auto;
    padding: 0 !important;
  }
}

.tab-item {
  width: 100%;
  padding: 11px 0;
  display: inline-block;
  flex-direction: row;
  align-items: center;
  border-radius: 48px;
  align-self: stretch;
  margin: 4px 0 !important;
  cursor: pointer;
  background: $color-white;

  &:hover {
    background: $color-anti-flash-white;
  }

  &:focus-within {
    background: $color-anti-flash-white;
    border: 1px solid $color-dark-blue;
  }

  &:active {
    background: $color-white-smoke;
    border: 1px solid $color-dark-blue;
  }

  @include break-point(ipad-portrait) {
    display: flex;
    padding: 0px;
    border-radius: 12px;
    justify-content: center;
    flex-direction: column;
  }

  @include break-point(mobile) {
    display: flex;
    padding: 0px;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    flex-direction: column;
  }
  @include break-point(mobile, ipad-portrait) {
    height: 72px;
  }
  @include break-point(mobile-xs) {
    display: flex;
    padding: 0px;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    flex-direction: column;
    height: 92px;
  }
}

.tab-item-active {
  color: $color-primary-blue;
  background: $color-white-smoke;

  &:hover {
    background: $color-white-smoke;
  }

  &:focus-within {
    background: $color-white-smoke;
    border: 1px solid $color-dark-blue;
  }

  @include break-point(mobile, mobile-xs) {
    min-width: auto;
    max-width: auto;
  }
}

.label-with-icon-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 0.4rem;
  @include break-point(desktop, desktop-xl, desktop-xxl) {
    flex-direction: row;
  }
}

.nav-font {
  flex: none;
  flex-grow: 0;
  line-height: 20px;
  margin: 0 !important;
  color: $color-dark-gray;
  font-size: 16px !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;

  @include break-point(mobile, mobile-xs, ipad-portrait, tablet) {
    line-height: 20px !important;
    font-size: 14px !important;
    text-align: -webkit-center !important;
  }
  @include break-point(mobile-xs) {
    font-size: 14px !important;
  }
}

.nav-font-active {
  color: $color-primary-blue;
}

.nav-site-new {
  display: inline-block !important;
  width: 100%;
}

.item {
  @include break-point(mobile, mobile-xs, ipad-portrait, tablet) {
    gap: 4px;
    display: flex;
    justify-content: space-between;
  }
  @include break-point(desktop, desktop-xl, desktop-xxl) {
    width: 100%;
  }
}

.nav-icon {
  width: 2rem !important;
  height: 1.5rem !important;
  fill: $color-dark-gray !important;

  @include break-point(mobile, mobile-xs, ipad-portrait, tablet) {
    display: flex;
    width: 1rem !important;
    height: 1rem !important;
  }
}

.nav-icon-active {
  fill: $color-primary-blue !important;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

.action-btns-sticky {
  @extend .sticky;
  margin: 0;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  flex-flow: row-reverse;

  height: 94px;
  background: $color-white;
  box-shadow: 0px 0px 24px $color-dark-fog;
  border-radius: 0px;
  z-index: 1;
}

.action-btns {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include break-point(desktop-xl, desktop-xxl) {
    margin-left: 25%;
  }
  @include break-point(desktop) {
    margin-left: 30%;
  }
}

.btn {
  width: 342px;
  height: 56px;
}

.save-btn {
  @extend .btn;
  margin-bottom: 0;
}

.cancel-btn {
  @extend .btn;
}

.sticky-save-btn {
  margin: 0;
  min-width: 137px;
}

.sticky-cancel-btn {
  margin: 0 0.5rem 0 0;
  color: $color-primary-blue;
  width: 100px;
  height: 56px !important;
}

.spacer {
  width: 1rem;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
