@import '../../../styles/mixins';
@import '../../../styles/_colors.scss';

.vds-footer {
  background: $color-white;
  padding: 0 1.5rem;
  @include break-point(mobile, mobile-xs, ipad-portrait, tablet) {
    padding: 0 1rem;
  }

  .vds-grid {
    padding: 0;

    .row {
      @include break-point(ipad-portrait, mobile, mobile-xs) {
        justify-content: center;
      }
      .links-col {
        max-width: fit-content;
        @include break-point(mobile-xs) {
          margin: auto;
        }
        ul {
          text-align: center;
        }
        p {
          padding: 0;
        }
      }

      .footer-logo {
        @include break-point(mobile-xs, mobile, ipad-portrait) {
          margin-bottom: 0rem;
        }
      }
    }
    .links-col,
    .copyright-col,
    .region-col {
      p {
        text-align: center;
      }
      @include break-point(ipad-portrait, mobile, mobile-xs) {
        text-align: center;
        ul {
          li {
            line-height: 1.5rem;
          }
        }
      }
    }
    .footer-region {
      padding: 0.5rem 0rem;
      .footer-region-label {
        .footer-select-dropdown {
          width: auto;
          padding-top: 0;
          margin-top: 0;
          min-width: auto !important;
          div:first-child {
            border: 1px solid var(--theme-input-underline, $color-white);
          }
          --theme-icon-color: gray;
          span {
            height: auto;
          }
          select {
            font-size: 12px;
            color: $color-visa-light-blue;
            padding-top: 0;
            padding-bottom: 0;
            height: auto;
            &:focus {
              border: 1px solid $color-visa-light-blue;
            }
            &:hover {
              border: 1px dotted $color-visa-light-blue;
            }
          }
        }
      }
    }
  }
}
