@import '../../../src/styles/mixins';
@import '../../../src/styles/colors';

.container {
  display: flex;
  width: fit-content;
  align-items: center;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  padding: 40px 96px 62px;
  margin: 2rem auto 0 auto;
  border: 1px solid $color-light-gray;
  @include break-point(mobile-xs, mobile) {
    padding: 40px 30px 62px;
    border-radius: 32px;
  }
  @include break-point(desktop, desktop-xl, desktop-xxl) {
    margin-top: 7rem;
  }
}

.inner-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title-text {
  width: 444px;
  font-size: 30px;
  font-weight: 600;
  span {
    display: block;
    text-align: center;
  }
  margin-bottom: 2.5rem;
  @include break-point(mobile-xs, mobile) {
    width: 335px;
    font-size: 24px;
  }
}
.btn-style {
  text-transform: none;
  width: 342px;
  height: 56px;
}
