@import '../../../src/styles/mixins.scss';
@import '../../../src/styles/colors';

.profile {
  max-width: 906px;
  width: 100%;

  @include break-point(mobile-xs) {
    max-width: 767px;
  }

  @include break-point(desktop, desktop-xl, desktop-xxl) {
    margin: 0px auto;
  }

  &-title {
    margin-bottom: 24px;

    @include break-point(mobile, mobile-xs) {
      margin-bottom: 12px;
    }
  }

  &-sub-title {
    max-width: 381px;
    width: 100%;
    text-align: center;
    margin: 0 auto;

    @include break-point(mobile, mobile-xs) {
      max-width: 292px;
    }
  }

  &-card {
    border-radius: 32px;
    border: 1px solid $color-light-gray;
    background: $color-white;
    box-shadow: none !important;
    max-width: inherit;
    width: 100%;
    margin-top: 56px;

    @include break-point(mobile, mobile-xs) {
      margin-top: 32px;
    }
  }

  &-name {
    padding: 36px 32px 37px 48px;
    border-bottom: 1px solid $color-light-gray;

    @include break-point(mobile, mobile-xs) {
      padding: 39.5px 41px 40.5px 48px;
    }

    .label {
      margin-bottom: 0.25rem !important;
      font-weight: 600;
      line-height: 28px;
      font-size: 18px;
      @include break-point(mobile, mobile-xs) {
        font-size: 16px;
        height: 21px;
      }
    }

    .value {
      font-size: 18px;
      line-height: 28px;
      @include break-point(mobile, mobile-xs) {
        font-size: 14px;
      }
    }
  }

  &-item {
    padding: 40px 6px 40px 48px;
    border-bottom: 1px solid $color-light-gray;

    :hover {
      cursor: pointer;
    }

    @include break-point(mobile, mobile-xs) {
      padding: 39px 6px 40px 48px;
    }

    .label {
      margin-bottom: 0.25rem !important;
      @include break-point(mobile, mobile-xs) {
        font-size: 16px;
        height: 21px;
      }
    }

    .value {
      font-size: 18px;
      line-height: 28px;
      @include break-point(mobile, mobile-xs) {
        font-size: 14px;
        line-height: 18px; //we added the span to phoneNote, to make it adjust we added this.
      }
    }

    .unverified-label {
      color: $color-dark-gray;
      font-weight: 400;
    }

    p {
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
      color: $color-black;
      margin-bottom: 0px;
    }

    .delete-label {
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
      color: $color-meroon;
      margin-bottom: 0.25rem;
    }

    .delete-text {
      color: $color-light-maroon !important;
      font-size: 14px;
      line-height: 19px;
      font-weight: 400;
    }
  }

  &-delete {
    padding: 40px 6px 48px 48px;

    :hover {
      cursor: pointer;
    }

    p {
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
      color: $color-black;
      margin-bottom: 0px;
    }

    .delete-label {
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
      color: $color-meroon;
      margin-bottom: 0.25rem;
      @include break-point(mobile, mobile-xs) {
        font-size: 16px;
        height: 26px;
      }
    }

    .delete-text {
      color: $color-meroon !important;
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      @include break-point(mobile, mobile-xs) {
        font-size: 14px;
        line-height: 19px;
        height: 42px;
      }
    }
  }
}

.icon-col {
  align-self: center;
  display: flex;

  .arrow-icon {
    color: $color-medium-blue;
    width: 16px;
    height: 16px;
    font-weight: 800;
  }

  .delete-icon-button {
    &:focus {
      span svg {
        fill: $color-meroon !important;
      }
    }
  }

  .delete-icon {
    width: 16px;
    height: 16px;
    fill: $color-meroon;
    font-weight: 800;
  }
}

.alert-row {
  margin: 0 auto !important;
}

.profile-content {
  margin-left: 16px;
  margin-right: 16px;
}
