@import '../../node_modules/@visa/vds-web/dist/_sass/vds-colors';
@import './_mixins';
@import './colors';
@import './variables';

$advanced-filter-panel-height: 224px;
$advanced-filter-panel-width: 1236px;
$filter-panel-padding: 10px;

/* Styles common to make padding 0 */
.pt-0 {
  padding-top: 0;
}

/*styles to reduce margins when horizontal nav bar is open*/
.vertical-nav-open {
  .al-global-alert {
    margin: 0 -60px 30px -60px;
    z-index: 0;
    padding-left: 12px;
    padding-right: 6px;

    @include break-point(tablet, mobile) {
      margin: 0 -52px 30px -52px;
      z-index: 0;
      padding-left: 12px;
      padding-right: 16px;
    }

    .alert-link {
      margin-left: 3px;
    }
  }

  .main-content {
    margin: 0 $al-main-content-lr-margin-nav-open;

    @include break-point(tablet, mobile) {
      margin: 0 $al-main-content-lr-margin-tablet;
    }
  }

  .filters-container {
    margin: 0 -60px 30px -60px;
    padding: 18px 48px;

    .arrow-back-button {
      margin-bottom: 15px;
    }

    @include break-point(tablet, mobile) {
      margin: 0 -52px 30px -52px;
      padding: 18px 48px;

      .arrow-back-button {
        margin-bottom: 15px;
      }
    }
  }
}

.al-global-alert {
  margin: 0 -86px 30px -86px;
  z-index: 0;
  padding-left: 12px;
  padding-right: 6px;

  .al-alert-details {
    align-items: center;
  }

  @include break-point(tablet, mobile) {
    margin: 0 -52px 30px -52px;
    z-index: 0;
    padding-left: 12px;
    padding-right: 16px;
  }
}

.filters-container {
  margin: 0 -86px 30px -86px;
  padding: 18px 74px;
  background: $color-fog;

  .arrow-back-button {
    margin-bottom: 15px;
  }

  .button-container {
    margin-right: 16px;
  }

  .filters-first-row {
    align-items: center;

    .col {
      padding-bottom: 0;
    }
  }

  .filters-second-row {
    align-items: flex-start;

    .col {
      padding-top: 0;
    }

    .vds-btn-group {
      padding: 12px;
    }
  }
}

.filter-item-container {
  max-width: 150px;
  padding-right: 17px;
  border-right: 1px solid $color-pale-white;

  &:not(:first-child) {
    padding-left: 17px;
  }

  .vds-state--filled .vds-input-bar {
    border: none;
  }

  .vds-input--select {
    color: $color-blue;
    background-color: $color-fog;
  }
}

.al-footer {
  background-color: $color-white;
}

.al-breadcrumbs {
  .vds-breadcrumbs {
    display: inline-flex;
    margin-bottom: 0;

    @include break-point(tablet, mobile) {
      white-space: nowrap;
    }
  }

  .vds-breadcrumb {
    display: flex;
    align-items: baseline;

    @include break-point(tablet, mobile) {
      white-space: nowrap;
    }

    a {
      text-decoration: none;
    }

    h1 {
      white-space: nowrap;

      &.ellipsis {
        width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.breadcrumb-plain {
        color: $color-black;
      }

      &.breadcrumb-link {
        color: $color-medium-blue;
      }
    }
  }

  .vds-breadcrumb:last-of-type {
    svg {
      display: none;
    }
  }
}

.atm-locator-accordion {
  .vds-accordion-heading {
    background-color: $color-fog;
  }

  .vds-accordion-heading:focus {
    outline: none;
  }

  .vds-accordion-heading:hover {
    background-color: none;
  }
}

.vds-action-bar {
  padding: 8px 16px;

  .vds-btn-icon--light:hover:not(:active) {
    background-color: $color-workflow-grey;
  }
}

.top-section-card {
  .card-heading {
    color: $color-indigo;
  }
}

.vds-dialog-card {
  padding-top: 24px;
}

.al-data-table-action-bar {
  margin-bottom: 24px;
}

.no-padding {
  padding: 0px !important;
}

.vds-input,
.vds-input-cvv .vds-input,
.vds-input.vds-state--filled {
  min-width: 100%;

  .vds-input-label,
  .vds-input-label:nth-of-type(1) {
    margin-bottom: 0.4em;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: $color-black;
  }

  &.vds-state--error .vds-input-message {
    margin-top: 0.4em;
    display: flex;
    align-items: center;
  }
}

.two-col .col--lg-5 {
  @include two-col(10px);
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  opacity: 0.85;
  z-index: 1000;
}

.two-col .col--lg-5 {
  @include two-col(10px);
}

.code-input-cvv .vds-input,
.vds-input-cvv .vds-input.vds-state--filled {
  min-width: 100%;
}

.page-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.5px;
  text-align: center;
  margin-bottom: 0px;

  @include break-point(mobile-xs, mobile, ipad-portrait) {
    font-size: 24px;
    line-height: 30px;
  }
}

.panel-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  @include break-point(mobile-xs) {
    font-size: 17px;
    line-height: 24px;
  }

  @include break-point(mobile, ipad-portrait) {
    line-height: 24px;
  }
}

.vds-dialog.vds-state--show {
  display: flex !important;
}
h1:focus {
  outline: none;
}
