@import '../../../styles/colors';

.page-subtitle {
  margin: 16px;
}

.delete-icon {
  max-width: 16px;
  margin-right: 16px !important;
  fill: $color-meroon !important;
}

.save-btn {
  margin-bottom: 0;
}

.remove-btn {
  margin-top: 1rem;
}
