@import '../../../../src/styles/mixins.scss';
@import '../../../../src/styles/colors';

.wrapper {
  padding: 16px;
  width: auto;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  position: relative;

  .card-tile-art {
    height: 46px;
    width: 76px;
    border-radius: 2px;

    img {
      width: 16px !important;
      height: 5px !important;
      left: 5px;
      bottom: 5px;
    }
  }

  .card-content {
    margin-left: 16px;
  }

  .card-row {
    position: relative;
    align-items: center;
    flex-wrap: nowrap;
  }

  .card-text {
    font-size: 1rem;
    font-weight: 600;
    line-height: 24px;
  }

  .card-sub-text {
    font-size: 14px;
    color: $color-dark-gray;
    font-weight: 400;
  }

  .icon-wrapper {
    background: none;
    border: none;
    padding: 0;
    position: absolute;
    top: 40%;
    right: 1em;

    .icon {
      fill: $color-black;
    }
  }
}
