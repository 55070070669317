@import '../../../styles/mixins';
@import '../../../styles/colors';

.cvv {
  --theme-vds-input-width: auto;
  text-align: center;

  input {
    text-align: center;
    font-weight: 400;
    font-size: 32px;
    letter-spacing: 5px;
    height: 54px;
    box-sizing: border-box;
  }
}
